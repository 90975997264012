import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 635.000000 626.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,626.000000) scale(0.100000,-0.100000)">


<path d="M3010 6020 c-145 -13 -235 -26 -355 -50 -22 -4 -68 -13 -102 -20 -35
-7 -63 -15 -63 -19 0 -4 -8 -7 -17 -8 -30 -1 -218 -62 -326 -105 -113 -45
-410 -194 -415 -209 -2 -5 -8 -9 -12 -9 -9 0 -84 -45 -130 -77 -55 -40 -187
-140 -214 -163 -40 -34 -125 -108 -156 -135 -77 -67 -297 -323 -371 -430 -19
-27 -37 -52 -40 -55 -3 -3 -34 -52 -69 -110 -72 -119 -174 -321 -214 -425 -15
-38 -31 -79 -36 -90 -5 -11 -21 -58 -35 -105 -15 -47 -33 -103 -41 -125 -17
-50 -71 -321 -78 -395 -25 -242 -29 -369 -18 -543 7 -100 19 -218 27 -262 9
-44 17 -93 20 -110 15 -119 98 -396 166 -560 39 -93 176 -365 202 -399 4 -6
39 -58 77 -116 38 -58 74 -111 81 -119 7 -7 43 -52 81 -100 102 -126 347 -363
478 -462 195 -147 322 -225 510 -314 63 -30 128 -62 143 -70 16 -8 33 -13 38
-9 5 3 9 1 9 -4 0 -5 10 -12 23 -15 12 -2 60 -18 107 -35 47 -16 112 -38 145
-47 33 -9 82 -23 109 -31 26 -9 63 -16 81 -18 17 -2 36 -5 41 -9 17 -10 300
-47 441 -56 91 -7 193 -7 285 -1 140 10 424 46 442 57 5 4 24 7 42 9 41 4 223
56 334 96 47 17 95 33 108 35 12 3 22 10 22 15 0 5 4 6 10 3 5 -3 22 1 37 9
16 8 84 42 153 75 225 109 444 249 433 277 -2 7 6 18 19 24 22 11 22 10 4 -4
-11 -9 -17 -19 -13 -22 10 -10 165 116 288 233 108 104 212 216 274 298 17 21
37 46 45 55 8 8 48 65 87 125 40 61 76 115 80 121 30 41 153 281 191 374 71
172 139 387 167 530 9 47 21 103 26 125 31 134 48 533 30 700 -6 55 -12 117
-14 137 -2 21 -10 45 -18 55 -9 11 -10 14 -1 9 15 -9 15 5 2 59 -6 22 -19 85
-31 140 -11 55 -27 120 -35 145 -74 237 -115 343 -207 527 -53 107 -160 292
-189 328 -5 6 -45 60 -90 120 -45 61 -84 112 -88 115 -4 3 -34 37 -68 76 -89
105 -278 281 -289 270 -4 -4 -1 -12 7 -17 13 -9 13 -10 0 -6 -8 2 -15 10 -15
18 -1 24 -14 38 -111 110 -55 41 -99 67 -105 63 -7 -4 -9 -3 -6 3 7 11 -6 22
-103 84 -88 57 -350 191 -447 230 -230 91 -546 175 -728 193 -212 21 -274 25
-395 24 -74 0 -173 -4 -220 -8z m-133 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m-180 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m1402 -67 c-2 -1 -13 -9 -24 -17 -19 -14 -19 -14 -6 3 7 9 18 17 24 17
6 0 8 -1 6 -3z m-2109 -167 c-24 -15 -37 -11 -16 5 11 8 22 12 25 9 3 -3 -1
-9 -9 -14z m-306 -168 c2 -4 -5 -9 -17 -9 -17 -2 -19 0 -7 7 18 12 18 12 24 2z
m-329 -242 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m3825
-40 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-4100 -234 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m-49 -49 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m4600 -219 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z
m-4780 -15 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m2384
-304 c26 -78 31 -81 156 -78 60 1 111 0 114 -3 4 -3 -31 -27 -77 -53 -117 -68
-114 -64 -88 -138 12 -34 29 -75 37 -90 20 -38 11 -44 -29 -19 -94 58 -140 82
-148 77 -6 -3 -7 1 -4 9 3 9 4 16 1 16 -3 0 -49 -27 -102 -60 -53 -34 -100
-58 -105 -55 -8 5 -1 24 45 130 7 17 15 37 17 45 3 10 -26 34 -89 73 -52 33
-93 61 -91 63 2 1 48 4 103 5 55 1 109 5 121 9 14 6 27 28 42 72 11 34 26 77
32 93 l12 30 18 -38 c10 -21 26 -60 35 -88z m2550 71 c9 -16 13 -30 11 -30 -3
0 -12 14 -21 30 -9 17 -13 30 -11 30 3 0 12 -13 21 -30z m-5082 -10 c-3 -12
-8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m2772 -110 c-3 -5 -11 -10
-16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-122 -7 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-2793 -187 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2480 -150 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-1560 -197 c4 -5 49 -135
101 -289 51 -154 96 -280 99 -280 6 0 128 356 169 492 l26 87 268 3 c148 2
272 1 275 -2 4 -3 7 -229 7 -503 l0 -497 -172 0 -173 0 -2 357 -3 356 -69
-204 c-38 -112 -94 -278 -124 -369 -31 -90 -57 -159 -60 -152 -3 9 -44 12
-152 12 l-148 0 -51 153 c-29 83 -66 195 -83 247 -32 100 -94 281 -109 315 -4
11 -8 -141 -8 -348 l0 -369 -161 1 c-109 1 -160 -2 -160 -9 0 -7 -4 -7 -12 1
-10 9 -12 121 -10 507 1 273 6 499 11 502 16 10 535 0 541 -11z m1612 -170
c-2 -154 -1 -179 11 -172 37 21 185 159 182 169 -2 7 8 22 23 35 l27 24 -24
-27 c-33 -38 -16 -35 28 5 20 17 66 58 103 90 l65 58 273 0 c150 0 274 -2 277
-4 2 -2 -13 -17 -34 -32 -170 -127 -352 -278 -347 -287 4 -7 2 -8 -4 -4 -13 8
-82 -40 -82 -56 0 -8 112 -153 130 -168 3 -3 22 -26 41 -51 19 -25 51 -65 71
-90 20 -24 73 -89 118 -144 45 -55 93 -113 106 -129 13 -16 22 -31 21 -32 -2
-2 -117 -4 -257 -4 -233 -1 -254 0 -263 16 -10 17 -11 17 -17 -1 -6 -18 -7
-17 -12 3 -4 12 -2 20 3 16 17 -10 9 5 -39 78 -73 112 -113 163 -124 160 -5
-1 -7 2 -4 7 5 8 -38 84 -65 115 -4 4 -53 -29 -109 -75 l-102 -83 4 -118 c2
-65 0 -118 -4 -118 -5 0 -98 -1 -208 -1 l-200 0 -3 500 -2 501 195 2 c107 2
202 2 210 0 13 -3 15 -29 12 -183z m2508 158 c0 -9 -2 -9 -8 0 -6 9 -18 4 -45
-18 -20 -17 -35 -35 -32 -40 4 -5 0 -6 -8 -3 -8 3 -41 -17 -78 -48 -35 -30
-82 -67 -104 -83 -22 -16 -48 -36 -57 -45 -9 -8 -46 -37 -82 -64 -36 -27 -66
-52 -66 -55 0 -7 17 -28 244 -310 190 -234 236 -293 236 -302 0 -9 -487 -17
-499 -9 -5 3 -68 94 -141 202 l-133 198 -36 -27 c-20 -15 -42 -33 -49 -40 -7
-7 -18 -13 -23 -13 -5 0 -8 -10 -6 -21 1 -12 -5 -24 -13 -26 -13 -4 -13 -3 0
6 8 5 11 13 7 17 -4 4 -15 0 -25 -9 -10 -10 -22 -17 -27 -17 -4 0 -14 -8 -21
-17 -18 -24 -19 -224 -2 -241 14 -14 35 -13 -219 -12 -151 1 -193 -2 -194 -12
0 -7 -2 -8 -5 -2 -2 5 -5 236 -7 512 l-2 502 210 0 210 0 -3 -180 c-2 -99 0
-180 3 -180 11 0 155 133 150 138 -2 3 -13 -1 -22 -9 -16 -13 -17 -12 -5 3 8
10 22 16 31 15 23 -3 109 77 108 99 -1 9 5 19 13 21 13 4 13 3 0 -6 -8 -5 -11
-13 -8 -17 4 -3 37 20 74 52 l67 59 266 5 c260 5 301 2 301 -23z m-2130 9 c0
-2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-230 -191 c-7 -9 -15
-13 -19 -10 -3 3 1 10 9 15 21 14 24 12 10 -5z m-1173 -177 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m4060 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-4154 -273 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15
13 8 -4z m3144 -371 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-976 -53 c33 1 60 0 62 -2 2 -2 13 -42 25 -89 31 -125 61 -226 63 -217 6 23
7 27 9 30 1 1 18 64 38 141 l37 138 70 1 c55 0 70 -3 68 -14 -5 -36 -14 -69
-28 -95 -8 -16 -15 -34 -15 -40 0 -7 -24 -85 -54 -174 l-54 -163 -72 0 c-39 0
-74 4 -79 9 -4 5 -18 45 -30 89 -13 44 -29 94 -36 110 -7 17 -26 71 -41 120
-15 50 -30 92 -33 95 -6 5 -7 7 -16 60 -5 30 -4 31 11 16 12 -13 32 -16 75
-15z m-1990 -11 c-11 -9 -12 -55 -2 -83 12 -30 17 -28 -74 -31 l-60 -1 -1
-179 -2 -180 -57 3 c-40 2 -60 -2 -66 -11 -10 -16 -9 -32 -12 192 l-2 175 -60
2 c-33 1 -66 2 -73 2 -7 1 -10 6 -6 12 4 7 7 34 8 61 l1 49 181 0 c99 0 186 4
194 9 13 8 43 -11 31 -20z m1438 -12 c-5 -9 -8 -32 -7 -50 l3 -33 -105 -5
-106 -5 1 -30 0 -30 95 -1 95 -1 0 -53 0 -53 -97 2 -98 2 0 -45 0 -46 109 0
109 0 4 -55 c1 -30 -1 -55 -5 -55 -5 0 -5 -6 -1 -12 6 -10 4 -10 -8 0 -12 10
-58 13 -183 11 l-166 -2 0 244 0 243 170 -3 c123 -2 171 0 171 8 0 8 5 8 14
-1 9 -9 11 -18 5 -30z m768 20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m1238 -48 c0 -26 -4 -51 -10 -56 -5 -5 -55 -9 -110 -9 -107 0 -101 3
-96 -50 1 -12 19 -14 99 -13 l97 2 -3 -36 c-2 -20 1 -39 7 -43 6 -3 11 -14 11
-23 0 -15 -2 -15 -10 -2 -5 8 -10 11 -10 5 0 -6 -39 -10 -97 -10 l-98 0 -3
-42 -3 -42 113 -3 113 -3 0 -53 0 -53 -178 0 -177 0 -2 242 -1 242 161 -2
c103 -2 162 2 167 8 13 22 30 -12 30 -59z m-4365 31 c67 -40 91 -102 92 -230
1 -78 -3 -94 -26 -138 -42 -76 -67 -86 -224 -93 -73 -3 -141 -2 -150 2 -9 4
-11 7 -4 7 10 1 12 50 10 236 -2 129 0 236 4 238 4 1 67 1 139 -1 103 -2 136
-7 159 -21z m423 -67 c16 -49 31 -96 34 -104 4 -8 7 -17 8 -20 0 -3 16 -50 34
-105 42 -129 41 -125 38 -129 -1 -1 4 -10 11 -18 17 -21 7 -24 -76 -20 l-69 4
-9 36 -9 37 -67 1 -68 2 -6 -34 c-3 -19 -11 -37 -16 -40 -8 -5 -87 -8 -138 -5
-5 1 -2 23 9 50 10 28 16 52 13 55 -3 4 0 6 6 6 7 0 12 7 12 15 0 9 21 78 46
153 25 75 47 145 49 154 10 48 20 53 97 52 l73 -1 28 -89z m799 0 c16 -49 32
-98 37 -109 4 -11 23 -67 41 -125 18 -58 36 -109 39 -115 4 -5 7 -18 7 -29 -1
-16 -10 -18 -71 -18 l-70 -1 -13 42 -14 41 -63 0 c-68 0 -71 -2 -79 -44 -3
-16 -7 -31 -10 -33 -6 -7 -134 -8 -141 -2 -2 3 4 29 15 57 11 29 18 52 15 52
-3 0 1 10 9 23 8 12 17 33 19 47 3 14 23 81 46 149 23 68 41 130 41 137 0 17
10 19 92 18 l72 -1 28 -89z m626 82 c36 -19 61 -50 77 -97 l14 -39 -32 0 c-18
0 -49 -3 -69 -6 -34 -6 -39 -4 -48 20 -11 31 -31 41 -65 32 -59 -15 -18 -49
95 -80 78 -21 112 -59 118 -130 6 -70 -1 -94 -35 -131 -38 -41 -74 -55 -142
-55 -87 0 -120 10 -155 44 -30 31 -62 114 -48 127 4 4 33 9 65 11 57 5 58 4
71 -26 19 -46 42 -61 73 -50 69 24 47 73 -46 100 -77 22 -121 51 -137 90 -30
72 -8 146 54 182 45 26 166 31 210 8z m1613 -236 c0 -166 -3 -245 -10 -244 -6
1 -36 2 -66 3 l-55 1 0 240 0 239 58 4 c31 1 61 3 65 2 5 0 8 -111 8 -245z
m372 241 c52 -20 131 -121 110 -141 -4 -5 -32 -15 -61 -24 -53 -15 -53 -15
-64 8 -19 43 -83 59 -110 29 -65 -73 -35 -243 42 -243 31 0 70 39 70 70 0 23
11 32 25 20 6 -5 21 -11 33 -13 12 -2 25 -6 28 -10 4 -4 16 -7 26 -7 24 0 23
-30 -2 -79 -47 -93 -133 -124 -253 -92 -35 10 -58 25 -87 58 -48 55 -57 95
-49 217 5 77 10 98 33 133 37 56 93 83 173 83 34 0 73 -4 86 -9z m-1076 -21
c70 -42 70 -192 0 -229 -37 -20 -33 -39 6 -32 5 1 6 -2 3 -7 -2 -4 15 -48 38
-97 24 -50 40 -92 36 -95 -5 -3 -41 -3 -81 0 l-73 5 -46 90 c-28 56 -52 91
-63 93 -16 3 -18 -5 -16 -98 1 -55 -1 -104 -5 -107 -3 -4 -6 -1 -6 6 0 9 -18
12 -62 10 l-63 -3 -5 242 -5 242 155 0 c136 0 159 -2 187 -20z m549 -20 c0
-12 -4 -16 -9 -11 -5 5 -6 14 -3 20 10 15 12 14 12 -9z m-4159 -32 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1300 -20 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m3510 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-40 -84 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-3800 -26 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2744 -11
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-1096 -107 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2545 -145 c0
-5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m2410
-25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-430 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m1377 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2786
-1015 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m169 -173 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m50 -50
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m4020 7 c0 -2 -10 -12 -22 -23 l-23 -19 19 23 c18 21 26 27 26 19z m-115 -85
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-3815 -12 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m85 -70 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3655 -10
c-13 -11 -25 -20 -28 -20 -3 0 3 9 13 20 10 11 23 20 28 20 6 0 0 -9 -13 -20z
m-485 -318 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-2089
-230 c-16 -2 -32 0 -37 5 -5 5 7 7 29 4 34 -5 35 -6 8 -9z m1512 1 c-10 -2
-28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z"/>
<path d="M1077 2673 c-4 -3 -7 -62 -7 -130 l0 -123 29 0 c42 0 77 38 85 93 12
77 -24 167 -61 153 -8 -3 -11 -1 -8 4 7 11 -28 14 -38 3z"/>
<path d="M1528 2574 c-9 -38 -15 -70 -13 -72 2 -2 18 -2 37 0 l34 3 -21 69
-21 68 -16 -68z"/>
<path d="M2326 2582 c-22 -80 -21 -82 20 -82 33 0 36 2 30 23 -4 12 -11 43
-17 70 -6 26 -12 47 -14 47 -1 0 -10 -26 -19 -58z m21 -14 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3794 2653 c-7 -39 0 -53 29 -53 44 0 67 13 74 38 8 32 -18 53 -64
49 -28 -2 -34 -6 -39 -34z m86 -23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6
8 10 11 10 2 0 4 -4 4 -10z"/>
</g>
</svg>

        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}